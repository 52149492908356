import React, { useState } from 'react';

import LoginForm from './login-form/login-form.jsx';
import OrderConsPopup from './order-cons-popup/order-cons-popup.jsx';
import NotificationPopup from './notification-popup/notification-popup.jsx';

function CentralBlockWithOrder({ t }) {
  const [openConsulPopup, setOpenConsulPopup] = useState(false);
  const [openNotifiPopup, setOpenNotifiPopup] = useState(false);

  return (
    <>
      {openNotifiPopup && (
        <NotificationPopup close={() => setOpenNotifiPopup(false)} t={t} />
      )}
      {openConsulPopup && (
        <OrderConsPopup
          close={() => setOpenConsulPopup(false)}
          openNotifi={setOpenNotifiPopup}
          t={t}
        />
      )}
      <div className='container__item'>
        <div className='container__title'>
          {t('main.title_telphin', 'Телфин.Офис')}
        </div>
        <margin-4></margin-4>
        <p className='container__text'>
          {t(
            'main.container_text.functionality',
            'Широкий функционал АТС, надежная телефонная связь и индивидуальный подход к клиентам.'
          )}
        </p>
        <margin-16></margin-16>
        <div className='container__item-wrapper'>
          <a
            className='btn'
            id='newUserFormTrigger'
            href='#'
            onClick={() => setOpenConsulPopup(true)}
          >
            {t('main.container_item_wrapper.connect', 'Подключиться')}
          </a>
          <a id='loginFormTrigger' href='#'>
            {t('main.container_item_wrapper.login', 'Войти в аккаунт')}
          </a>
        </div>
      </div>
    </>
  );
}

const Main = ({ t, wl, language }) => {
  return (
    <main className='container'>
      {!wl && <CentralBlockWithOrder t={t} />}
      <LoginForm t={t} language={language} />
    </main>
  );
};

export default Main;
